import { useState } from 'react'
import styled from 'styled-components'
import IUserMail, { UserMailsDataType } from 'src/interfaces/UserMail'
import MailCard from '../shared/MailCard'
import { useTranslation } from 'react-i18next'
import { Pagination } from 'antd'
import { useQuery } from '@apollo/client'
import { CommonLoader } from 'src/components/shared/Loader'
import Filters from './Filters'
import { USER_MAILS } from '@/graphql/UserMail'
import { UsermailsFiltersProps } from '@/interfaces/Filters'
import NoUserMails, { NoLastMailContent } from '@/CustomerArea/userMails/NoUserMails'

export interface SelectedMailsProps {
  id: string
  viewed: boolean
}

const ListingMails = () => {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState(1)
  const [pageLength, setPageLength] = useState(10)
  const [selectedMails, setSelectedMails] = useState<SelectedMailsProps[]>([])
  const [filterData, setFilterData] = useState<UsermailsFiltersProps>({})

  const {
    data: dataUserMails,
    loading: loadingUserMails,
    error: errorUserMails
  } = useQuery<UserMailsDataType>(USER_MAILS, {
    variables: {
      pagination: {
        page: currentPage,
        pageLength: pageLength
      },
      filter: filterData ?? {}
    }
  })

  const userMails = dataUserMails?.userMails?.userMails
  const pagination = dataUserMails?.userMails.pagination

  const onFilterChange = (filters: UsermailsFiltersProps) => {
    setSelectedMails([])
    setFilterData(filters)
    setCurrentPage(1)
  }

  const onPaginationChange = (e) => {
    setCurrentPage(e)
    setSelectedMails([])
  }

  return (
    <LastMailsWrapper>
      <Filters
        onFilterChange={onFilterChange}
        userMails={userMails}
        selectedMails={selectedMails}
        setSelectedMails={setSelectedMails}
        setPageLength={setPageLength}
        setCurrentPage={setCurrentPage}
      />

      {loadingUserMails && (
        <div className='p-4'>
          <CommonLoader />
        </div>
      )}
      {errorUserMails && <span>{`${t('common:error')}! ${errorUserMails.message}`}</span>}
      {!loadingUserMails && userMails && (
        <>
          {userMails.length === 0 && <NoLastMailContent>{t('userMails:global.noUserMails')}</NoLastMailContent>}

          {userMails.length > 0 && (
            <>
              {userMails.map((mail: IUserMail) => {
                return (
                  <MailCard
                    mail={mail}
                    key={mail.id}
                    setSelectedMails={setSelectedMails}
                    selectedMails={selectedMails}
                  />
                )
              })}
              <Pagination
                current={currentPage}
                total={pagination?.total}
                onChange={onPaginationChange}
                showSizeChanger={false}
                hideOnSinglePage={true}
                pageSize={pageLength}
              />
            </>
          )}
        </>
      )}
      {!loadingUserMails && !userMails && <NoUserMails />}
    </LastMailsWrapper>
  )
}

export const LastMailsWrapper = styled.div`
  padding-top: 16px;
  .ant-pagination {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    & > li {
      margin: 0;
      margin-right: 10px;
      border: none;
      color: #171a1c;
      font-size: 16px;
      & > a:hover {
        color: #1576e1;
      }
      .ant-pagination-item-link {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
      }
    }
    .ant-pagination-item-active {
      color: #1576e1;
    }
  }
`
export const LastMailsTitle = styled.h3`
  color: #171a1c;
  font-size: 20px;
  font-weight: 600;
  @media (max-width: 900px) {
    font-size: 18px;
  }
`

export default ListingMails
