import React, { createContext } from 'react'
import { ApolloError, useQuery } from '@apollo/client'
import { NOTIFICATIONS_QUERY } from 'src/graphql/Notification'

export const NotificationContext = createContext({} as NotificationContextProps)
export const NotificationContextProvider = NotificationContext.Provider

interface IProps {
  children: React.ReactNode
}

export interface NotificationContextProps {
  loading: boolean
  error: ApolloError | undefined
  refetch: () => void
  kyc: {
    userMails: number
    legalDocuments: number
  }
}

const NotificationProvider = (props: IProps) => {
  const { data, loading, error, refetch } = useQuery(NOTIFICATIONS_QUERY)

  return (
    <NotificationContextProvider value={{ kyc: data?.notifications, loading, error, refetch }}>
      {props.children}
    </NotificationContextProvider>
  )
}

export default NotificationProvider
