import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { Modal as ModalAntD } from 'antd'
import { useLocation, Navigate } from 'react-router-dom'
import { CommunicationSection, CommunicationStrengthRestriction } from '@/interfaces/Enum'
import ICommunication from '@/interfaces/Communication'
import { WithTranslation, withTranslation } from 'react-i18next'
import sectionMatches from './utilities/CommunicationSectionMatches'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import InformationCircle from 'src/images/info-circle-blue.svg'
import WarningCircle from 'src/images/information-circle-o.svg'
import { useSearchParams } from 'react-router-dom'

interface CommunicationModalProps {
  children: React.ReactNode
}

const Modal = withTranslation()(
  ({
    communication,
    showModal,
    toggleModal,
    t
  }: {
    communication: ICommunication
    showModal: boolean
    toggleModal: () => void
  } & WithTranslation) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const Parser = require('html-to-react').Parser
    const htmlToReactParser = new Parser()

    return (
      <ModalAntD
        open={showModal}
        onCancel={toggleModal}
        keyboard={communication.intensity !== CommunicationStrengthRestriction.extrem}
        maskClosable={communication.intensity !== CommunicationStrengthRestriction.extrem}
        footer={() => (<>
          {
            communication.communicationType !== 'verification_waiting' &&
          communication.intensity !== CommunicationStrengthRestriction.extrem && (
              <div className='text-center mt-4'>
                <button onClick={toggleModal} className='section-button black-button'>
                  {communication.labelOk || t('common:continue')}
                </button>
              </div>
            )
          }
        </>)}>
        <div
          className={
            `info-shape large-shape
              ${communication.communicationType === 'verification_waiting' ? 'notification-shape' : 'warning-shape'
      }`}
        >
          <div className='first-cercle mt-6'>
            <img
              src={communication.communicationType === 'verification_waiting' ? InformationCircle : WarningCircle}
              className='inside-circle'
            />
          </div>
        </div>
        <div className='text-center mt-4'>{htmlToReactParser.parse(communication.message)}</div>
      </ModalAntD>
    )
  }
)

const CommunicationModal: React.FC<CommunicationModalProps> = ({ children }) => {
  const { domiciliation }: any = useContext(DomiciliationContext)
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const [searchParams] = useSearchParams()

  const [currentPathname, setCurrentPathname] = useState(location.pathname)
  const [showModal, setShowModal] = useState(true)
  const [alreadyShowed, setAlreadyShowed] = useState(false)
  const [fromRedirection, setFromRedirection] = useState(false)

  const communication = searchParams.get('continuekyc') !== 'true' && domiciliation?.communications?.find(
    (c: ICommunication) => c.intensity !== CommunicationStrengthRestriction.notification
  )

  const urlMatch = communication?.sectionsToAllow?.find((section: CommunicationSection) => {
    if (
      location.pathname === '/' &&
      !communication?.sectionsToAllow.includes('dashboard') &&
      communication?.sectionsToAllow !== null
    ) {
      return false
    }
    return sectionMatches[section].match(basePath)
  })

  const mustRedirect = (communication: ICommunication) => {
    if (communication.sectionLanding == CommunicationSection.dashboard ) {
      return false
    }
    if (
      location.pathname === '/' &&
      !communication?.sectionsToAllow?.includes(CommunicationSection.dashboard) &&
      communication?.sectionsToAllow !== null
    ) {
      return true
    }
    if (
      communication.sectionsToRestrict &&
      communication.sectionsToRestrict.find((section: CommunicationSection) => {
        return sectionMatches[section].match(basePath)
      })
    ) {
      return true
    }

    if (
      communication.sectionsToAllow &&
      !communication.sectionsToAllow.find((section: CommunicationSection) => {
        return sectionMatches[section].match(basePath)
      })
    ) {
      return true
    }

    if (
      communication.intensity === CommunicationStrengthRestriction.soft &&
      !alreadyShowed &&
      location.pathname !== sectionMatches[communication.sectionLanding!]
    ) {
      return true
    }

    return false
  }

  const toggleModal = () => {
    setShowModal(false)
    setAlreadyShowed(true)
    setFromRedirection(false)
  }

  useEffect(() => {
    if (location.pathname !== currentPathname) {
      setCurrentPathname(location.pathname)
      setShowModal(true)
    }

    setFromRedirection(location.state && location.state.fromRedirection)
  }, [])

  return communication ? (
    <>
      {mustRedirect(communication) && !urlMatch ? (
        <Navigate to={sectionMatches[communication?.sectionLanding]} state={{ fromRedirection: true }} />
      ) : communication.message === null ||
        (communication.intensity === CommunicationStrengthRestriction.soft && alreadyShowed && !fromRedirection) ||
        (communication?.intensity === CommunicationStrengthRestriction.hard &&
          alreadyShowed &&
          communication?.communicationType === 'alert' &&
          urlMatch === 'subscription') ? (
          (children as JSX.Element)
        ) : (
          <>
            <Modal communication={communication} showModal={showModal} toggleModal={toggleModal} />
            {children}
          </>
        )}
    </>
  ) : (
    <>{children as JSX.Element}</>
  )
}

export default CommunicationModal
