import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Radio, Row, Col } from 'antd'
import { CreditCardOutlined } from '@ant-design/icons'
import IPaymentSource from 'src/interfaces/PaymentSource'
import { PrettyLink, Box, LargeSpace } from '../styles'
import NewCard from './NewCard'
import { ContactContext } from 'src/contexts/contactContext'
import BillingInformation from './BillingInformation'

interface IProps {
  setPaymentMethod: (paymentMethod: any) => void;
  setDisabledPaymentButton: (displayed: boolean) => void;
}

const CardSelect = (props: IProps) => {
  const { t } = useTranslation()

  const { contact }: any = useContext(ContactContext)
  const cards = contact.paymentSources.filter(
    (paymentSource: IPaymentSource) => paymentSource.sourceType === 'card',
  )

  const defaultCard = cards.find((card: IPaymentSource) => card.currentSource)
  const [selectedCard, setSelectedCard] = useState<any>(defaultCard)
  const [showNewCardSection, setShowNewCardSection] = useState<boolean>(false)
  const [newCardToken, setNewCardToken] = useState<string | undefined>()

  const selectCard = (id: string | undefined) =>
    setSelectedCard(cards.find((card: IPaymentSource) => card.id === id))

  useEffect(() => {
    if (selectedCard || newCardToken) {
      props.setDisabledPaymentButton(false)
    } else {
      props.setDisabledPaymentButton(true)
    }
  }, [selectedCard, newCardToken])

  useEffect(() => {
    if (selectedCard) {
      props.setPaymentMethod({ payment_method: selectedCard.token })
    } else if (newCardToken) {
      props.setPaymentMethod({
        payment_method: {
          card: { token: newCardToken },
          billing_details: {
            email: contact.email,
          },
        },
        setup_future_usage: 'off_session',
      })
    }
  }, [selectedCard, newCardToken])

  return (
    <Box>
      <h3>{t('payments:payment_method')}</h3>
      {/* no cards */}
      <Row>
        <Col>{!cards.length && <span>{t('payments:no_card')}</span>}</Col>
      </Row>

      <BeautifulRadioGroup
        onChange={(e) => selectCard(e.target.value)}
        value={selectedCard?.id}
      >
        <LargeSpace direction="vertical">
          {/* existing cards */}
          {cards.map((card: IPaymentSource) => {
            return (
              <BeautifulRadio value={card.id} key={card.id}>
                <OverrideCreditCardOutlined />
                <div>
                  <span>
                    {t('payments:ending_by', {
                      number: card.last4Digits,
                    })}
                  </span>
                  <span>
                    {`Exp. ${t('common:short_date', {
                      date: new Date(card.expirationDate),
                    })}`}
                  </span>
                </div>
              </BeautifulRadio>
            )
          })}

          {/* add a card */}
          <TopMarginedRow>
            <Col>
              <PrettyLink
                onClick={ () => {
                  setSelectedCard(undefined)
                  setShowNewCardSection((currentValue) => !currentValue)
                }}
              >
                {t('payments:add_a_card')}
              </PrettyLink>
            </Col>
          </TopMarginedRow>

          {
            showNewCardSection &&
              <BillingInformation>
                <NewCardRadio value={undefined} key={'new_card'}>
                  <div>
                    <NewCard setNewCardToken={setNewCardToken} />
                  </div>
                </NewCardRadio>
              </BillingInformation>
          }
        </LargeSpace>
      </BeautifulRadioGroup>
    </Box>
  )
}

const BeautifulRadioGroup = styled(Radio.Group)`
  height: fit-content;
  width: inherit;
`

const BeautifulRadio = styled(Radio)`
  height: 70px;
  padding: 15px 0px;
  border-bottom: 0.5px solid #d7d7d7;
  color: #7b7e80;

  .ant-radio {
    top: -8px;
  }

  div {
    display: inline-block;
    margin-left: 15px;

    span {
      display: block;
      font-size: 12px;

      &:first-child {
        font-weight: bold;
      }
    }
  }
`

const NewCardRadio = styled(Radio)`
  height: 75px;

  & > span:last-child {
    width: stretch;

    & > div {
      margin-left: 15px;

      span:not(.validation-error) {
        display: block;
        color: #7b7e80;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
`

const OverrideCreditCardOutlined = styled(CreditCardOutlined)`
  font-size: 26px;
  vertical-align: top;
  margin-left: 25px;
`

const TopMarginedRow = styled(Row)`
  margin-top: 20px;
`

export default CardSelect
