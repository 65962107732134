import { useContext } from 'react'
import { NotificationContext } from 'src/contexts/notificationContext'
import Notification from '../Notifications'
import styled from 'styled-components'

const BurgerMenu = ({ showMobileMenu, setShowMobileMenu }) => {
  const { kyc } = useContext(NotificationContext)

  const mailCount = kyc?.userMails ?? 0

  return (
    <>
      <div className='desktop:!hidden flex'>
        <button className='relative group' onClick={() => setShowMobileMenu(!showMobileMenu)}>
          {mailCount > 0 && !showMobileMenu && (
            <span className='absolute -ml-3 -mt-1'>
              <Notification number={mailCount} />
            </span>
          )}
          <div
            className='flex flex-col justify-between w-[20px] h-[20px] transform transition-all
            duration-300 origin-center overflow-hidden'
          >
            <div
              className={
                'bg-neutral-700 h-[2px] w-7 transform transition-all duration-300 origin-left ' +
                (showMobileMenu ? 'translate-x-10' : '')
              }
            ></div>
            <div
              className={
                'bg-neutral-700 h-[2px] w-7 rounded transform transition-all duration-300 delay-75 ' +
                (showMobileMenu ? 'translate-x-10' : '')
              }
            ></div>
            <div
              className={
                'bg-neutral-700 h-[2px] w-7 transform transition-all duration-300 origin-left  delay-150 ' +
                (showMobileMenu ? 'translate-x-10' : '')
              }
            ></div>
            {showMobileMenu && (
              <MenuBurger
                className='absolute items-center justify-between w-12 flex opacity-0'
                style={{ left: '-2px' }}
              >
                <div className='absolute bg-neutral-700 h-[2px] w-5 rotate-45' style={{ left: '-14px' }}></div>
                <div className='absolute bg-neutral-700 h-[2px] w-5 -rotate-45'></div>
              </MenuBurger>
            )}
          </div>
        </button>
      </div>
    </>
  )
}

const MenuBurger = styled.div`
  transition: opacity 300ms ease-in-out 300ms;
  opacity: 1;
`

export default BurgerMenu
