import { Dispatch, SetStateAction, useState } from 'react'
import { Upload } from 'antd'
import { useTranslation } from 'react-i18next'
import Button from 'src/components/UIKit/components/Button/buttonWithBorder'
import plusSign from 'src/images/plusSign/plus-circle-blue.svg'
import { RcFile } from 'antd/es/upload'
import {
  DrawerContentWrapper,
  PlusIcon,
  ValidationErrorNotice,
  FileFormatNotice,
  FileDisplay,
  CustomModifyButton,
  ButtonsWrapper
} from './styles'

interface BasicUploadProps {
  selectedFile: RcFile | null
  setSelectedFile: Dispatch<SetStateAction<RcFile | null>>
  onSendDocument: () => void
  children?: React.ReactNode
  buttonText?: string
  withoutButton?: boolean
}

const BasicUpload = ({
  selectedFile,
  setSelectedFile,
  onSendDocument,
  children,
  buttonText,
  withoutButton
}: BasicUploadProps) => {
  const { t } = useTranslation()

  const [validationError, setValidationError] = useState('')
  const [disableButton, setDisableButton] = useState<boolean>(false)

  const beforeUpload = (file: RcFile) => {
    const isLt2M = file.size / 1024 / 1024 < 6000
    if (!isLt2M) {
      setValidationError(t('common:drawer.tooBig'))
    } else {
      setValidationError('')
    }
    return isLt2M
  }

  const onChange = (e) => {
    switch (e.file.status) {
    case 'done':
      setSelectedFile(e.file.originFileObj)
      break
    default:
      setSelectedFile(null)
    }
  }

  const onValidate = () => {
    if (selectedFile) {
      if (selectedFile.size / 1024 / 1024 < 6000) {
        setDisableButton(true)
        onSendDocument()
      }
    }
  }

  const onModification = (e) => {
    setDisableButton(false)
    return setSelectedFile(e.file.originFileObj)
  }

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const handleCancel = () => {
    setValidationError('')
    setDisableButton(false)
    setSelectedFile(null)
  }
  return !selectedFile ? (
    <DrawerContentWrapper>
      {children}
      <Upload
        accept='.jpg, .jpeg, .png, .pdf'
        customRequest={({ onSuccess }) => dummyRequest({ onSuccess })}
        onChange={onChange}
        beforeUpload={beforeUpload}
        showUploadList={false}
        maxCount={1}
      >
        <Button bgColor='white' justifyContent='center'>
          <PlusIcon src={plusSign} alt='icone +' />
          {buttonText ? buttonText : t('common:document.add')}
        </Button>
      </Upload>
      {validationError && <ValidationErrorNotice>{validationError}</ValidationErrorNotice>}
      <FileFormatNotice>{t('common:drawer.notice')}</FileFormatNotice>
    </DrawerContentWrapper>
  ) : (
    <>
      <FileDisplay>
        {selectedFile?.name}
        <Upload
          accept='.jpg, .jpeg, .png, .pdf'
          customRequest={({ onSuccess }) => dummyRequest({ onSuccess })}
          onChange={onModification}
          beforeUpload={beforeUpload}
          showUploadList={false}
          maxCount={1}
        >
          <CustomModifyButton>{t('common:modify')}</CustomModifyButton>
        </Upload>
      </FileDisplay>
      {validationError && <ValidationErrorNotice>{t('common:drawer.tooBig')}</ValidationErrorNotice>}
      {!withoutButton && (
        <ButtonsWrapper>
          <Button bgColor='blue' height='48px' justifyContent='center' onClick={onValidate} disabled={disableButton}>
            {t('common:document.send')}
          </Button>
          <Button bgColor='white' height='48px' justifyContent='center' onClick={handleCancel} disabled={disableButton}>
            {t('common:cancel')}
          </Button>
        </ButtonsWrapper>
      )}
    </>
  )
}

export default BasicUpload
