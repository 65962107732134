// @ts-nocheck
import React from 'react'
import { withFormik, FormikProps, Form as FormikForm, Field } from 'formik'
import { graphql, MutateProps } from '@apollo/client/react/hoc'
import IGenericError from '@/interfaces/GenericError'
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { USER_UPDATE_NAME_MUTATION } from '@/graphql/User'
import { SubmitLoader } from '@/components/shared/Loader'
import IUser from '@/interfaces/User'

interface IProps {
  onCloseModal: () => void
  user: IUser
  setAlert: any
}

interface IFormValues {
  firstname: string
  lastname: string
}

const Content = (props: FormikProps<IFormValues> & IProps) => {
  const { t } = useTranslation()
  const { touched, errors } = props

  return (
    <FormikForm>
      <div className='py-4'>
        <div className="mb-2">
          <label htmlFor="firstname" className="block text-neutral-700 font-bold mb-2">{t('security:firstname')}</label>
          {touched.firstname && errors.firstname && <div className="text-error-700">{errors.firstname}</div>}
          <Field
            type="text"
            id="firstname"
            name="firstname"
            className={
              `border-neutral-200 focus:shadow appearance-none border w-full py-2 px-3
              text-neutral-700 leading-tight focus:outline-none focus:shadow-outline
              ${props.touched.firstname && props.errors.firstname ? 'border-red-500' : ''}`}
            placeholder={t('security:firstname')}
          />
        </div>
        <div className="mb-2">
          <label htmlFor="lastname" className="block text-neutral-700 font-bold mb-2">{t('security:lastname')}</label>
          {touched.lastname && errors.lastname && <div className="text-error-700">{errors.lastname}</div>}
          <Field
            type="text"
            id="lastname"
            name="lastname"
            className={
              `border-neutral-200 focus:shadow appearance-none border w-full py-2 px-3
              text-neutral-700 leading-tight focus:outline-none focus:shadow-outline
              ${props.touched.lastname && props.errors.lastname ? 'border-red-500' : ''}`}
            placeholder={t('security:lastname')}
          />
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <button onClick={props.onCloseModal} className="section-button white-button mr-4" type="button">
          {t('common:cancel')}
        </button>
        <button type="submit" className="submit section-button" disabled={props.isSubmitting}>
          <SubmitLoader isSubmitting={props.isSubmitting} />
          <span>{t('security:update_name')}</span>
        </button>
      </div>
    </FormikForm>
  )
}

const FormUserName = withTranslation('security')(
  graphql<IProps & WithTranslation>(USER_UPDATE_NAME_MUTATION)(
    withFormik<IProps & WithTranslation & MutateProps, IFormValues>({
      mapPropsToValues: (props) => {
        return {
          firstname: props.user.firstname,
          lastname: props.user.lastname
        }
      },

      validationSchema: (props: any) => {
        return Yup.object().shape({
          firstname: Yup.string().required(props.t('security:validation_firstname')),
          lastname: Yup.string().required(props.t('security:validation_lastname'))
        })
      },

      handleSubmit: (values, { props, setSubmitting }) => {
        props
          .mutate({
            variables: values,
            update: (cache: any, { data }: { data: { error: IGenericError } }) => {
              if (data.error) {
                setSubmitting(false)
                props.setAlert({ type: 'error', text: data.error.message, size: 'small' })
              } else {
                props.onCloseModal()
                props.setAlert({
                  type: 'success',
                  text: props.t('security:validation_name_confirm'),
                  size: 'small'
                })
              }
            }
          })
          .catch(() => {
            setSubmitting(false)
          })
      }
    })(Content)
  )
)

export default FormUserName
