import * as React from 'react'
import { confirmAlert as originalConfirmAlert } from 'react-confirm-alert'
import InformationCircleO from '../../images/information-circle-o.svg'
import { Modal } from 'antd'

const confirmAlert = (properties: any) => {
  const handleClose = (onClose: () => void, onClick?: () => void) => {
    if (onClick) {
      onClick()
    }
    onClose()
  }

  return originalConfirmAlert(
    Object.assign(
      {
        customUI: ({
          onClose,
          title,
          message,
        }: {
          onClose: () => void;
          title?: string;
          message: string;
        }) => (
          <Modal
            open={true}
            title={title}
            footer={null}
            onCancel={onClose}
          >
            <div className="info-shape large-shape warning-shape">
              <div className='first-cercle mt-6'>
                <img src={InformationCircleO} className="inside-circle" />
              </div>
            </div>
            <div className='text-center mt-4'>
              {message}
            </div>
            {properties.buttons && (
              <div className="flex flex-wrap mt-4">
                {properties.buttons.map(
                  (button: {
                    label: string;
                    onClick?: () => void;
                    primary?: boolean;
                  }) => (
                    <div
                      key={button.label}
                      className={`flex-1 btn section-button mx-4 mt-2 ${button.primary ?
                        'primary-button' :
                        'white-button'
                      }`}
                      onClick={handleClose.bind(
                        undefined,
                        onClose,
                        button.onClick,
                      )}
                    >
                      {button.label}
                    </div>
                  ),
                )}
              </div>
            )}
          </Modal>
        ),
      },
      properties,
    ),
  )
}

export default confirmAlert
