import { useState, useEffect } from 'react'
import { Form, FormInstance, Radio, Collapse, Input, DatePicker } from 'antd'
import { compact } from '@sedomicilier/material-sd'
import { useTranslation } from 'react-i18next'

interface PoliticallyExposedProps {
  form: FormInstance
  title: string
  baseField?: number | string
  listName?: number | string
}

const PoliticallyExposed: React.FC<PoliticallyExposedProps> = ({
  form,
  title,
  baseField,
  listName
}) => {
  const { t } = useTranslation()

  const { Panel } = Collapse
  const [personallyExposed, setPersonallyExposed] = useState<boolean>(
    form.getFieldValue(compact([listName, baseField, 'personallyExposed']))
  )
  const [closeRelationExposed, setCloseRelationExposed] = useState<boolean>(
    form.getFieldValue(compact([listName, baseField, 'closeRelationExposed']))
  )

  useEffect(() => {
    if (!personallyExposed) {
      form.setFieldValue(compact([listName, baseField, 'functionPerformed']), undefined)
    }
    form.setFieldValue(compact([listName, baseField, 'cessationDate']), undefined)
  }, [personallyExposed])

  useEffect(() => {
    if (!closeRelationExposed) {
      form.setFieldValue(
        compact([listName, baseField, 'closeRelationFunctionPerformed']),
        undefined
      )
    }
    form.setFieldValue(compact([listName, baseField, 'closeRelationType']), undefined)
  }, [closeRelationExposed])

  return (
    <div className='w-full'>
      <Collapse
        ghost
        expandIconPosition='end'
        defaultActiveKey={personallyExposed || closeRelationExposed ? [1] : undefined}
        style={{padding: 0}}
      >
        <Panel
          key='1'
          className='text-4 font-semibold w-full'
          header={title}
        >
          <div className='text-5 font-normal'>
            <p>{t('legalRepresentative:politicallyExposed.description')} </p>

            <div className='w-full justify-end items-center gap-2 inline-flex mt-1'>
              <div className='w-4 h-4 relative'>
                <span className='ico-info text-neutral-500' />
              </div>
              <div className='grow shrink basis-0 text-neutral-500 text-6'>
                {t('legalRepresentative:politicallyExposed.exemples')}
              </div>
            </div>

            <Form.Item
              name={compact([baseField, 'personallyExposed'])}
            >
              <Radio.Group
                value={form.getFieldValue(compact([listName, baseField, 'personallyExposed']))}
                onChange={(e) => {
                  setPersonallyExposed(e.target.value)
                  form.setFieldValue(
                    compact([listName, baseField, 'personallyExposed']),
                    e.target.value
                  )
                }}
                className='flex flex-row gap-2 mt-1 w-fit'
              >
                <Radio value={true}>{t('common:yes')}</Radio>
                <Radio value={false}>{t('common:no')}</Radio>
              </Radio.Group>
            </Form.Item>

            <div className='flex flex-row gap-2'>
              <div className='w-[calc(50%_-_8px)]'>
                <Form.Item
                  name={compact([baseField, 'functionPerformed'])}
                  label={t(`legalRepresentative:politicallyExposed.label.functionPerformed`)}
                  rules={[
                    {
                      required: personallyExposed,
                      message: t(`legalRepresentative:politicallyExposed.validation.functionPerformed`)
                    }
                  ]}
                  className='m-0'
                >
                  <Input
                    placeholder={t('legalRepresentative:politicallyExposed.placeholder.functionPerformed')}
                    className='border border-neutral-200 py-1 px-2 rounded-[6px] h-5 w-full'
                    disabled={!personallyExposed}
                  />
                </Form.Item>
              </div>
              <div className='w-[calc(50%_-_8px)]'>
                <Form.Item
                  name={compact([baseField, 'cessationDate'])}
                  label={t(`legalRepresentative:politicallyExposed.label.cessationDate`)}
                  rules={[
                    {
                      required: form.getFieldValue(compact([listName, baseField, 'personallyExposed'])),
                      message: t(`legalRepresentative:politicallyExposed.validation.cessationDate`)
                    }
                  ]}
                  className='m-0'
                >
                  <DatePicker
                    placeholder={t('legalRepresentative:politicallyExposed.placeholder.cessationDate')}
                    className='border border-neutral-200 py-1 px-2 rounded-[6px] h-5 w-full'
                    disabled={!personallyExposed}
                  />
                </Form.Item>
              </div>
            </div>

            <p>{t('legalRepresentative:politicallyExposed.closeRelationDescription')}</p>

            <div className='w-full justify-end items-center gap-2 inline-flex mt-1'>
              <div className='w-4 h-4 relative'>
                <span className='ico-info text-neutral-500' />
              </div>
              <div className='grow shrink basis-0 text-neutral-500 text-6'>
                {t('legalRepresentative:politicallyExposed.closeRelationExemples')}
              </div>
            </div>

            <Form.Item
              name={compact([baseField, 'closeRelationExposed'])}
            >
              <Radio.Group
                value={
                  form.getFieldValue(
                    compact([listName, baseField, 'closeRelationExposed'])
                  )
                }
                onChange={(e) => {
                  setCloseRelationExposed(e.target.value)
                  form.setFieldValue(
                    compact([listName, baseField, 'closeRelationExposed']),
                    e.target.value
                  )
                }}
                className='flex flex-row gap-2 mt-1 w-fit'
              >
                <Radio value={true}>{t('common:yes')}</Radio>
                <Radio value={false}>{t('common:no')}</Radio>
              </Radio.Group>
            </Form.Item>
            <div className='flex flex-row gap-2'>
              <div className='w-[calc(50%_-_8px)]'>
                <Form.Item
                  name={compact([baseField, 'closeRelationFunctionPerformed'])}
                  label={t(`legalRepresentative:politicallyExposed.label.functionPerformed`)}
                  rules={[
                    {
                      required: closeRelationExposed,
                      message: t(`legalRepresentative:politicallyExposed.validation.closeRelationFunctionPerformed`)
                    }
                  ]}
                  className='m-0'
                >
                  <Input
                    placeholder={t('legalRepresentative:politicallyExposed.placeholder.closeRelationFunctionPerformed')}
                    className='border border-neutral-200 py-1 px-2 rounded-[6px] h-5 w-full'
                    disabled={!closeRelationExposed}
                  />
                </Form.Item>
              </div>
              <div className='w-[calc(50%_-_8px)]'>
                <Form.Item
                  name={compact([baseField, 'relationType'])}
                  label={t(`legalRepresentative:politicallyExposed.label.relationType`)}
                  rules={[
                    {
                      required: form.getFieldValue(compact([listName, baseField, 'closeRelationExposed'])),
                      message: t(`legalRepresentative:politicallyExposed.validation.relationType`)
                    }
                  ]}
                  className='m-0'
                >
                  <Input
                    placeholder={t('legalRepresentative:politicallyExposed.placeholder.relationType')}
                    className='border border-neutral-200 py-1 px-2 rounded-[6px] h-5 w-full'
                    disabled={!closeRelationExposed}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  )
}

export default PoliticallyExposed
