import LanguageSelect from '@/components/languageSelect'
import navigationSections from '@/components/layouts/SideMenu/NavigationSections'
import MobileNavItem from '@/components/layouts/Topbar/MobileNavItem'
import { AbilityContext } from '@/contexts/abilityContext'
import { DomiciliationContext } from '@/contexts/DomiciliationContext'
import { NotificationContext } from '@/contexts/notificationContext'
import Routing from '@/CustomerArea/Routing'
import { Dispatch, SetStateAction, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useMatch } from 'react-router'

interface MobileMenuProps {
  setShowMobileMenu: Dispatch<SetStateAction<boolean>>
}

const MobileMenu: React.FC<MobileMenuProps> = ({ setShowMobileMenu }) => {
  const { t } = useTranslation()

  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const match = useMatch(`${basePath}/*`)

  const ability = useContext(AbilityContext)
  const { domiciliation } = useContext(DomiciliationContext)
  const { kyc } = useContext(NotificationContext)

  const sideMenuSections = navigationSections({ domiciliation, Routing, kyc, ability, t })

  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='flex flex-col gap-1 p-2'>
        {sideMenuSections.map((section) => {
          if (section.logo || !section.display) {
            return null
          }
          return (
            <div
              className={`${
                section.route === match?.pathnameBase ? 'bg-neutral-200' : 'hover:bg-neutral-150'
              } m-0 text-neutral-700 rounded-small`}
              key={section.dataSpecId}
            >
              <MobileNavItem
                to={section.route}
                authorized={section.authorized}
                icon={section.icon}
                data-spec-id={section.dataSpecId}
                setShowMobileMenu={setShowMobileMenu}
                notification={section.notification}
                label={section.sectionTitle}
              />
            </div>
          )
        })}
      </div>
      <div>
        <LanguageSelect />
      </div>
    </div>
  )
}

export default MobileMenu
