import { useState, useEffect, useContext } from 'react'
import { BackButton, useWindowDimensions } from '@sedomicilier/material-sd'
import { useSelector, useDispatch } from '@/hooks/redux'
import { previousPage, setPath } from '@/reducers/workflow'
import { useLocation } from 'react-router-dom'
import { steps, ModuleType, stepsHideDomideal } from '@/workflows/modules'
import { useTranslation } from 'react-i18next'
import { Steps } from 'antd'
import { ContactContext } from 'src/contexts/contactContext'
import constants from 'src/constants'

const Navigation = () => {
  const { t } = useTranslation('navigation')
  const dispatch = useDispatch()
  const location = useLocation()

  const { width } = useWindowDimensions()
  const flow = useSelector((state) => state.workflow.flow)

  const pathname = location.pathname
  const { contact } = useContext(ContactContext)

  const selectedSteps = contact.hideDomideal ? stepsHideDomideal : steps
  const desktop = width && width >= parseInt(constants.breakpoints['xlarge-breakpoint-i'], 10)

  const [currentStep, setCurrentStep] = useState(selectedSteps.findIndex((item: ModuleType) => item.path === pathname))

  useEffect(() => {
    setCurrentStep(selectedSteps.findIndex((item: ModuleType) => item.path === pathname))
  }, [pathname])

  const onChange = (value: number) => {
    const newPath = selectedSteps[value].path
    if (flow.includes(newPath)) dispatch(setPath(newPath))
  }

  const getStatus = (index: number) => {
    if (index === currentStep) {
      return 'process'
    } else if (flow.includes(selectedSteps[index].path)) {
      return 'finish'
    } else {
      return 'wait'
    }
  }

  const className = desktop ? 'flex items-start flex-col m-2 fixed' : 'flex items-start flex-col m-2'

  useEffect(() => {
    const processStep = document.querySelector('.ant-steps-item-process')
    const finishSteps = document.querySelectorAll('.ant-steps-item-finish')

    if (processStep) {
      processStep.querySelector('.ant-steps-item-title')!.classList.add('!text-interaction', 'font-semibold')
      finishSteps.forEach((item) =>
        item.querySelector('.ant-steps-item-title')?.classList.remove('!text-interaction', 'font-semibold')
      )
      return
    }
    return
  }, [currentStep])

  if (pathname === '/legal-flow/onboarding') {
    return null
  }

  return (
    <div className={className}>
      <BackButton label={t('common:back')} size='large' onClick={() => dispatch(previousPage())} />
      <Steps
        progressDot
        className='mt-5'
        current={currentStep}
        onChange={onChange}
        direction='vertical'
        items={selectedSteps.map((item: ModuleType, index: number) => ({
          title: t(`steps.${item.name}`),
          status: getStatus(index),
          disabled: !flow.includes(item.path)
        }))}
      />
    </div>
  )
}

export default Navigation
