import * as React from 'react'
import { useQuery } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { useNavigate } from 'react-router-dom'
import { Modal as ModalAntD } from 'antd'
import { WithTranslation, withTranslation } from 'react-i18next'
import { DEMO_MODAL_QUERY, toggleDemoModal } from './Utils'
import CountryContext from 'src/contexts/countryContext'

const Modal = withTranslation()(
  ({
    toggleModal,
    redirectToSubscriptionFlow,
    t,
    isOpen,
  }: {
    toggleModal: () => void;
    redirectToSubscriptionFlow: (countryCode: string) => any;
    isOpen: boolean;
  } & WithTranslation) => {
    const { countryCode } = React.useContext(CountryContext)
    return (
      <ModalAntD
        open={isOpen}
        onCancel={toggleModal}
        className="demo-continue-modal"
        zIndex={1100}
        title={t('demo:title')}
        footer={() => (
          <>
            <button onClick={toggleModal} className="section-button white-button">
              {t('demo:continue')}
            </button>
            <button
              onClick={() => redirectToSubscriptionFlow(countryCode)}
              className="section-button primary-button"
            >
              {t('demo:start')}
            </button>
          </>
        )}
      >
      </ModalAntD>
    )
  },
)
/* eslint react/prop-types: 0 */

const DemoContinueModal = (props) => {
  const navigate = useNavigate()
  const { loading, error, data, refetch }: any = useQuery(DEMO_MODAL_QUERY)

  const toggleModal = (refetchQuery: any) => {
    toggleDemoModal(props.client.cache, false)
    refetchQuery()
  }

  const redirectToSubscriptionFlow = (countryCode: string) => {
    navigate(process.env['REACT_APP_SUBSCRIPTION_FLOW_LINK_' + countryCode]!)
  }
  if (loading || error) {
    return null
  }

  return (
    <Modal
      toggleModal={() => toggleModal(refetch)}
      redirectToSubscriptionFlow={redirectToSubscriptionFlow}
      isOpen={data?.demoModal?.show}
    />
  )
}

export default withApollo(DemoContinueModal)
