import ContactUsDropdown from '../ContactUsDropdown'
import AdministratorAccess from './AdministratorAccess'
import CartLink from 'src/components/shared/CartLink'
import DropdownContact from '../DropdownContact'
import { useLocation } from 'react-router-dom'
import BurgerMenu from './BurgerMenu'

const Topbar = ({ showMobileMenu, setShowMobileMenu }) => {
  const location = useLocation()
  const pathname = location.pathname

  return (
    <div className='h-[70px] bg-neutral-0 desktop:bg-neutral-0 border-b border-neutral-200 flex justify-between items-center gap-2 px-3 py-2'>
      <div className='flex justify-between items-center gap-3 flex-1 tablet:justify-start w-[50%]'>
        <span className='ico-sd-picto text-1 text-interaction mobile:hidden desktop:hidden' />
        <DropdownContact />
      </div>
      <div className='flex items-center gap-[20px]'>
        <AdministratorAccess />
        <ContactUsDropdown />
        {!pathname.includes('legal-flow') && <CartLink />}
        <BurgerMenu showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu} />
      </div>
    </div>
  )
}

export default Topbar
