import { UserMailContext } from '@/contexts/UserMailContext'
import NoUserMailSelected from '@/CustomerArea/userMails/selectedMailDetails/NoUserMailSelected'
import UserMailDetails from '@/CustomerArea/userMails/selectedMailDetails/UserMailDetails'
import { useContext } from 'react'

const SelectedMailDetails: React.FC = () => {
  const { selectedMail } = useContext(UserMailContext)

  return (
    <div className='w-[40%] py-0 px-5 mobile:px-3 mobile:w-full mobile:mx-auto mobile:my-0'>
      <div className='sticky top-[110px]'>{selectedMail ? <UserMailDetails /> : <NoUserMailSelected />}</div>
    </div>
  )
}

export default SelectedMailDetails
