import { NotificationContext } from '@/contexts/notificationContext'
import { UserMailContext } from '@/contexts/UserMailContext'
import { SelectedMailsProps } from '@/CustomerArea/userMails/UserMails/ListingMails'
import { USER_MAIL_UNVIEW_MUTATION, USER_MAIL_VIEW_MUTATION } from '@/graphql/UserMail'
import { useMutation } from '@apollo/client'
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'

interface ReadUnreadHandlerProps {
  selectedMails: SelectedMailsProps[]
  setSelectedMails: Dispatch<SetStateAction<SelectedMailsProps[]>>
}

const ReadUnreadHandler: React.FC<ReadUnreadHandlerProps> = ({ selectedMails, setSelectedMails }) => {
  const { setSelectedMail, selectedMail } = useContext(UserMailContext)
  const { refetch: refetchNotifications } = useContext(NotificationContext)

  const concernedMail = selectedMails.some((item) => item.id === selectedMail?.id)

  const selectedMailIds = selectedMails.map((item) => item.id)

  const [someUnviewed, setSomeUnviewed] = useState(selectedMails.some((mail) => !mail.viewed))

  const iconInteraction = selectedMails.length > 0 ? 'text-interaction' : 'text-neutral-300'
  const readIcon = someUnviewed ? 'ico-letter-open text-interaction' : `ico-letter-close ${iconInteraction}`

  const [markAsViewed] = useMutation(USER_MAIL_VIEW_MUTATION, {
    variables: {
      userMailIds: selectedMailIds
    },
    onCompleted: () => {
      refetchNotifications()
      const updatedSelectedMails = selectedMails.map((obj) => ({
        ...obj,
        viewed: true
      }))
      return setSelectedMails(updatedSelectedMails)
    }
  })

  const [markAsUnviewed] = useMutation(USER_MAIL_UNVIEW_MUTATION, {
    variables: {
      userMailIds: selectedMailIds
    },
    onCompleted: () => {
      refetchNotifications()
      // if the selected mail is in the array of checked mails then we setSelectedMail to undefined as requested
      concernedMail && setSelectedMail(undefined)
      // here we update the array of checked mails with new state for viewed, to keep visuals dynamical
      const updatedSelectedMails = selectedMails.map((obj) => ({
        ...obj,
        viewed: false
      }))
      return setSelectedMails(updatedSelectedMails)
    }
  })

  const onClick = () => {
    if (selectedMails.length === 0) return
    if (someUnviewed) {
      markAsViewed()
    } else {
      markAsUnviewed()
    }
  }

  useEffect(() => {
    setSomeUnviewed(selectedMails.some((mail) => !mail.viewed))
  }, [selectedMails])

  return <span className={`${readIcon} cursor-pointer text-2`} onClick={onClick} />
}

export default ReadUnreadHandler
