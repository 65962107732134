import React, { useContext, useEffect, useState } from 'react'
import { Divider, Input, Select, SelectProps, Space } from 'antd'
import { useMutation } from '@apollo/client'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import {
  USER_MAIL_TAG_CREATE_MUTATION,
  USER_MAIL_TAG_DETACH_MUTATION,
  USER_MAIL_TAG_ATTACH_MUTATION
} from 'src/graphql/Tag'
import { UserMailContext } from '../../../../contexts/UserMailContext'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const UserMailTags = () => {
  const { t } = useTranslation()
  const { Option }: any = Select
  const { domiciliation }: any = useContext(DomiciliationContext)
  const { selectedMail, setSelectedMail }: any = useContext(UserMailContext)
  const userMailTags = domiciliation.userMailTags

  const [options, setOptions] = useState<SelectProps['options']>(userMailTags)
  const [tag, setTag] = useState('')

  const [selectTagValue, setSelectTagValue] = useState(selectedMail.tag?.name)

  const [createTag, { data }] = useMutation(USER_MAIL_TAG_CREATE_MUTATION, {
    onCompleted: () => setTag('')
  })
  const [attachTag, { data: attachData }] = useMutation(USER_MAIL_TAG_ATTACH_MUTATION)

  const [removeTag, { data: detachData }] = useMutation(USER_MAIL_TAG_DETACH_MUTATION, {
    onCompleted: () => setOptions(detachData)
  })

  const handleCreateTag = (e) => {
    e.preventDefault()

    createTag({
      variables: {
        name: tag
      }
    })
  }

  const handleAttachTag = (event, value) => {
    attachTag({
      variables: {
        userMailId: selectedMail.id,
        tagId: value.id
      }
    })
    setSelectTagValue(value.value)
  }

  const handleRemove = ({ e, name }) => {
    e.stopPropagation()

    removeTag({
      variables: {
        userMailId: selectedMail.id,
        name
      }
    })
    setSelectTagValue(null)
  }

  useEffect(() => {
    if (data) {
      return data && setOptions(data.userMailTagCreate.tags)
    } else {
      setOptions(userMailTags)
    }
  }, [data])

  useEffect(() => {
    setSelectTagValue(selectedMail.tag?.name)
  }, [selectedMail])

  useEffect(() => {
    if (detachData) {
      setSelectedMail(detachData.userMailTagDetach.userMail)
      return setOptions(detachData.userMailTagDetach.tags)
    } else {
      setOptions(userMailTags)
    }
  }, [detachData])

  useEffect(() => {
    if (attachData) {
      setSelectedMail(attachData.userMailTagAttach.userMail)
      return setOptions(attachData.userMailTagAttach.tags)
    } else {
      setOptions(userMailTags)
    }
  }, [attachData])

  return (
    <TagWrapper>
      <TagTitle>{t('userMails:global.tags')}</TagTitle>
      <Select
        placeholder='Tag'
        className='flex items-center'
        value={selectTagValue}
        onChange={(event, value) => {
          handleAttachTag(event, value)
        }}
        optionLabelProp='value'
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '0 8px 4px' }}>
              <Input
                placeholder={t('userMails:tag.tagName')}
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                style={{ borderRadius: '8px' }}
              />
              <TagAddButton onClick={handleCreateTag}>{t('userMails:tag.add')}</TagAddButton>
            </Space>
          </>
        )}
      >
        {options?.map((item) => (
          <Option key={item.id} value={item.name} id={item.id}>
            <TagLine>
              <TagName bgColor={item.color}>{item.name}</TagName>
              <TagDeleteButton onClick={(e) => handleRemove({ e, name: item.name })}>x</TagDeleteButton>
            </TagLine>
          </Option>
        ))}
      </Select>
    </TagWrapper>
  )
}

export const TagWrapper = styled.div`
  margin-bottom: 20px;
  .ant-select {
    width: 100%;
    .ant-select-selector {
      height: 48px;
      border-radius: 8px;
      border-color: #e3e6e8;
    }
  }
`
export const TagTitle = styled.h4`
  color: #171a1c;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`
export const TagLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const TagName = styled.div`
  padding: 2px 6px;
  color: #fff;
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
`
export const TagDeleteButton = styled.button`
  border: none;
  background-color: transparent;
  &:hover {
    cursor: pointer;
  }
`
export const TagAddButton = styled.button`
  background-color: #1576e1;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  color: white;
`
export default UserMailTags
