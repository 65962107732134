import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Menu, Dropdown, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { openValidationPopup, openErrorPopup } from 'src/components/UIKit'
import UpsertContactModal from './UpsertContactModal'
import DropdownButton from '@/images/dropdown-button.svg'
import { CONTACT_OF_SHARING_DESTROY_MUTATION } from '@/graphql/ContactOfSharing'
import { CONTACT_OF_SHARING_QUERY } from '@/graphql/ContactOfSharing'

interface IProps {
  contact: any
}

const Contact = (props: IProps) => {
  const { t } = useTranslation()
  const { contact } = props

  const [openUpsertContactModal, setOpenUpsertContactModal] = useState(false)
  const [contactRemove] = useMutation(CONTACT_OF_SHARING_DESTROY_MUTATION, {
    refetchQueries: [
      { query: CONTACT_OF_SHARING_QUERY },
    ],
  })

  const { confirm } = Modal

  const showDeleteConfirm = () => {
    confirm({
      title: t('team:remove_contact.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('team:remove_contact.description'),
      okText: t('team:remove_contact.button_confirm'),
      okType: 'danger',
      cancelText: t('team:remove_contact.button_cancel'),
      onOk() {
        contactRemove({ variables: { id: contact.id } }).then(() => {
          openValidationPopup(
            t('team:remove_contact.popup_success_title'),
            t('team:remove_contact.popup_success_description', { email: contact.email }),
          )
        }).catch((e) => openErrorPopup(t('team:remove_contact.popup_error_title'), e.message))
      },
    })
  }

  const menu = (
    <Menu>
      <Menu.Item key={`menu_item.delete.${contact.id}`}>
        <a href={void(0)} onClick={() => showDeleteConfirm()}>{t('team:remove_contact.link')}</a>
      </Menu.Item>
      <Menu.Item key={`menu_item.update.${contact.id}`}>
        <a href={void(0)} onClick={() => setOpenUpsertContactModal(true)}>{t('team:upsert_contact.link')}</a>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <UpsertContactModal
        openModal={openUpsertContactModal}
        setOpenModal={setOpenUpsertContactModal}
        contact={contact}
      />
      <div className="team-row" key={contact.id}>
        <div className="team-column team-contact-name">
          <IdentityWrapper>
            <span translate="no" className="notranslate">
              {
                contact.firstname && contact.lastname &&
                `${contact.firstname} ${contact.lastname}`
              }
            </span>
          </IdentityWrapper>
          {contact.withProcuration &&
            <WithProcuration>{t('team:with_procuration_label')}</WithProcuration>
          }
        </div>
        <div className="team-column team-category">{contact.category}</div>
        <div className="team-column team-contact-email">{contact.email}</div>
        <div className="team-column team-action">
          <Dropdown overlay={menu} trigger={['click']} overlayClassName="team-dropdown">
            <img src={DropdownButton} className="team-dropdown-button" />
          </Dropdown>
        </div>
      </div>
    </>
  )
}

const IdentityWrapper = styled.div`
  display: inline-block;
  vertical-align: sub;
`

const WithProcuration = styled.div`
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  vertical-align: sub;
  margin-left: 10px;
  padding: 4px 6px 4px 6px;
  color: #FFFFFF;
  background: linear-gradient(90deg, #1DBE6D 0%, #FFCD6B 132%);
  border-radius: 4px;
`

export default Contact
