import { SelectedMailsProps } from '@/CustomerArea/userMails/UserMails/ListingMails'
import { Select } from 'antd'
import { Dispatch, SetStateAction } from 'react'

interface PageLengthSetterProps {
  setPageLength: Dispatch<SetStateAction<number>>
  setCurrentPage: Dispatch<SetStateAction<number>>
  setSelectedMails: Dispatch<SetStateAction<SelectedMailsProps[]>>
}

const PageLengthSetter: React.FC<PageLengthSetterProps> = ({ setPageLength, setSelectedMails, setCurrentPage }) => {
  const handleChange = (value: number) => {
    setPageLength(value)
    setCurrentPage(1)
    setSelectedMails([])
  }

  return (
    <div className='flex items-center gap-2 w-fit text-5'>
      <Select
        defaultValue={10}
        onChange={handleChange}
        options={[
          { value: 10, label: '10 / page' },
          { value: 25, label: '25 / page' },
          { value: 50, label: '50 / page' },
          { value: 100, label: '100 / page' }
        ]}
      />
    </div>
  )
}

export default PageLengthSetter
