import React from 'react'
import Routing from '@/CustomerArea/Routing'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faQuestionCircle, faPen, faChevronRight } from '@sedomicilier/pro-light-svg-icons'
import { ZendeskAPI as zendeskAPI } from 'react-zendesk'
import { Divider, Dropdown } from 'antd'

const ContactUsDropdown = () => {
  const { t } = useTranslation()
  const openWidget = () => zendeskAPI('webWidget', 'open')

  return (
    <Dropdown
      menu={{ items: [] }}
      overlayStyle={{ background: 'white', boxShadow: '0 5px 20px 0 #0000001a', borderRadius: '8px' }}
      dropdownRender={(menu) => (
        <div>
          {React.cloneElement(menu as React.ReactElement, { style: { boxShadow: 'none', background: 'none' } })}
          <a
            href={Routing.FAQ}
            target='_blank'
            rel='noreferrer'
            className='flex p-3 hover:no-underline hover:bg-neutral-150 hover:text-neutral-600'
          >
            <FontAwesomeIcon icon={faQuestionCircle} className='flex-initial' />
            <span className='flex-1 px-2 -mt-0.5'>{t('nav:faq')}</span>
            <FontAwesomeIcon icon={faChevronRight} className='flex-initial' />
          </a>
          <Divider style={{ margin: 0 }} />
          <a onClick={openWidget} className='flex p-3 hover:no-underline hover:bg-neutral-150 hover:text-neutral-600'>
            <FontAwesomeIcon icon={faPen} className='flex-initial' />
            <span className='flex-1 px-2 -mt-0.5'>{t('nav:contact')}</span>
            <FontAwesomeIcon icon={faChevronRight} className='flex-initial' />
          </a>
          <Divider style={{ margin: 0 }} />
          <a
            href='https://wa.me/33745892760'
            target='_blank'
            rel='noreferrer'
            className='flex p-3 hover:no-underline hover:bg-neutral-150 hover:text-neutral-600'
          >
            <FontAwesomeIcon icon={faWhatsapp} className='flex-initial' />
            <span className='flex-1 px-2 -mt-0.5'>{t('nav:whatsapp')}</span>
            <FontAwesomeIcon icon={faChevronRight} className='flex-initial' />
          </a>
        </div>
      )}
    >
      <div className='font-semibold'>
        <a className='mobile:inline-block mobile:py-0.25'>
          <span className='ico-life-ring text-1 text-neutral-700' />
          <span className='tablet:hidden mobile:hidden'>
            &nbsp;
            <h3 className='inline' translate='no'>
              {t('nav:support')}
            </h3>
          </span>
        </a>
      </div>
    </Dropdown>
  )
}

export default ContactUsDropdown
