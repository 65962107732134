import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'

interface IElement {
  text: string;
  route?: string;
}

interface IProps {
  elements: IElement[];
}

const Breadcrumb = (props: IProps) => {
  const { elements } = props

  return (
    <BreadcrumbRow>
      <Col flex={1}>
        {elements.map((element) => {
          const isLastItem:boolean = elements.indexOf(element) === elements.length - 1

          return (
            <React.Fragment key={element.text}>
              {buildBreadcrumbLink(isLastItem, element)}
              {!isLastItem && <Separator />}
            </React.Fragment>
          )
        })}
      </Col>
    </BreadcrumbRow>
  )
}

const Separator = () => {
  return <SeparatorContainer>/</SeparatorContainer>
}

export const BreadcrumbRow = styled(Row)`
  height: 50px;
  margin-bottom: 20px;
`

const NormalLink = styled(Link)`
  color: #7b7e80;
`

const CurrentLink = styled(Link)`
  color: #1576e1;
`

const UnclickableLink = styled.span`
  color: #7b7e80;
`

const UnclickableCurrentLink = styled.span`
  color: #1576e1;
`

const SeparatorContainer = styled.span`
  color: #7b7e80;
  margin: 0px 15px;
`

const buildBreadcrumbLink = ( isLastItem: boolean, element: IElement) => {
  let linkComponent: JSX.Element
  const missingRoute = !element.route

  switch (true) {
  case (isLastItem && missingRoute):
    linkComponent = <UnclickableCurrentLink>{element.text}</UnclickableCurrentLink>
    break
  case (!isLastItem && missingRoute):
    linkComponent = (<UnclickableLink>{element.text}</UnclickableLink>)
    break
  case (isLastItem && !missingRoute):
    linkComponent = (<CurrentLink to={element.route}>{element.text}</CurrentLink>)
    break
  default:
    linkComponent = <NormalLink to={element.route}>{element.text}</NormalLink>
    break
  }

  return linkComponent
}
export default Breadcrumb
