import { Checkbox } from '@sedomicilier/material-sd'
import { useTranslation } from 'react-i18next'
import { Form, Input, FormInstance } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import AddressFormItems from '@/components/UIKit/components/AddressFormItems'
import Envelope from './Envelope'
import IForwardingAddress from '@/interfaces/ForwardingAddress'

interface FormContentProps {
  countryCode?: string
  form: FormInstance
  fullAddress?: IForwardingAddress
  setValidate: (value: boolean) => void
  validateState: boolean
  validate: boolean
}

const FormContent: React.FC<FormContentProps> = ({
  countryCode,
  form,
  fullAddress,
  setValidate,
  validateState,
  validate
}) => {
  const { t } = useTranslation('forwardingAddress')
  const name = Form.useWatch('name', form)
  const address = Form.useWatch('address', form)
  const complement = Form.useWatch('complement', form)

  return (
    <>
      <Form.Item
        data-testid='name-input'
        name='name'
        label={t('form.recipient')}
        rules={[{ required: true, message: t('form.validations.recipient') }]}
      >
        <Input data-testid='recipient-input' placeholder={t('form.placeholders.recipient')} />
      </Form.Item>
      <AddressFormItems form={form} initialAddress={fullAddress?.fullAddress} />
      {countryCode !== address?.countryCode && (
        <span data-testid='warning-forwarding-foreign' className='text-warning-500 text-5 mb-2'>
          {t('warning_foreign')}
        </span>
      )}
      {!!validateState && (
        <>
          <Envelope name={name} address={address} complement={complement} />
          <Checkbox
            data-testid='checkbox'
            label={t('form.checkbox')}
            onChange={(e: CheckboxChangeEvent) => setValidate(e.target.checked)}
            checked={validate}
          />
        </>
      )}
    </>
  )
}

export default FormContent
