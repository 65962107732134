import { useTranslation } from 'react-i18next'

const ParcelsManagement: React.FC = () => {
  const { t } = useTranslation('domiciliationPage')

  return (
    <>
      <h4 className='title-3 text-2 mb-2'>{t('drawers.mailsOfferDetails.parcelsManagement.title')}</h4>
      <p className='mb-1'>{t('drawers.mailsOfferDetails.parcelsManagement.intro')}</p>
      <h5 className='title-3 mb-1'>{t('drawers.mailsOfferDetails.parcelsManagement.centerReception.title')}</h5>
      <p>{t('drawers.mailsOfferDetails.parcelsManagement.centerReception.intro')}</p>
      <ul className='list-inside list-disc pl-1'>
        {['li1', 'li2'].map((element: string, idx: number) => (
          <li
            key={idx}
            dangerouslySetInnerHTML={{
              __html: t(`drawers.mailsOfferDetails.parcelsManagement.centerReception.${element}`)
            }}
          />
        ))}
      </ul>
      <p
        className='mb-3'
        dangerouslySetInnerHTML={{
          __html: t('drawers.mailsOfferDetails.parcelsManagement.centerReception.description')
        }}
      />

      <h5 className='title-3 mb-1'>{t('drawers.mailsOfferDetails.parcelsManagement.parcelsReception.title')}</h5>
      <p>{t('drawers.mailsOfferDetails.parcelsManagement.parcelsReception.intro')}</p>
      <ul className='list-inside list-disc pl-1 mb-1'>
        {['li1', 'li2', 'li3', 'li4'].map((element: string, idx: number) => (
          <li
            key={idx}
            dangerouslySetInnerHTML={{
              __html: t(`drawers.mailsOfferDetails.parcelsManagement.parcelsReception.${element}`)
            }}
          />
        ))}
      </ul>
      <p
        className='mb-3'
        dangerouslySetInnerHTML={{
          __html: t('drawers.mailsOfferDetails.parcelsManagement.parcelsReception.description')
        }}
      />
    </>
  )
}

export default ParcelsManagement
