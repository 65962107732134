import { useEffect } from 'react'
import ISearchSociety from '@/interfaces/SearchSociety'
import { Form, FormInstance } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SearchSelect from '@/components/UIKit/components/SearchSociety/Select'
import SocietySearchManual from '@/components/UIKit/components/SearchSociety/Manual'

interface MoraleFormProps {
  form: FormInstance
  id: number
}

const MoraleForm: React.FC<MoraleFormProps> = ({ form, id }) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(false)
  const [manualSearch, setManualSearch] = useState<boolean>(
    !!form.getFieldValue(['shareholders', id, 'address'])
  )

  useEffect(() => {
    form.setFieldValue(['shareholders', id, 'shareholderType'], 'moral')
  }, [])

  const onChange = (society: ISearchSociety) => {
    form.setFields([
      {
        name: ['shareholders', id, 'societyName'],
        value: society.name
      },
      {
        name: ['shareholders', id, 'legalStatusName'],
        value: society.legalStatusName
      },
      {
        name: ['shareholders', id, 'address'],
        value: {
          streetAddress: society.streetAddress,
          postalCode: society.postalCode,
          country: society.country,
          countryCode: society.countryCode,
          city: society.city
        }
      },
      {
        name: ['shareholders', id, 'leaderLastname'],
        value: society.leaderLastname
      },
      {
        name: ['shareholders', id, 'leaderFirstname'],
        value: society.leaderFirstname
      }
    ])
    setManualSearch(true)
  }

  return (
    <>
      <Form.Item name={[id, 'shareholderType']} initialValue='moral' hidden={true} />
      <SearchSelect
        loading={loading}
        setLoading={setLoading}
        onChange={onChange}
        setManualSearch={setManualSearch}
        placeholder={t('legalRepresentative:morale.search.placeholder')}
      />
      {
        manualSearch &&
        <SocietySearchManual form={form} baseField={id} listName='shareholders' />
      }
    </>
  )
}

export default MoraleForm
