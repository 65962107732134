// @ts-nocheck
import { withFormik } from 'formik'
import { graphql, MutateProps } from '@apollo/client/react/hoc'
import IGenericError from '@/interfaces/GenericError'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { USER_PASSWORD_UPDATE_MUTATION } from '@/graphql/User'
import Content from './components/Content'

interface IProps {
  onCloseModal: () => void
  setAlert: any
}

interface IFormValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const FormPassword = withTranslation('security')(
  graphql<IProps & WithTranslation>(
    USER_PASSWORD_UPDATE_MUTATION,
  )(
    withFormik<
        IProps & WithTranslation & MutateProps,
        IFormValues
      >({
        mapPropsToValues: () => {
          return {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
          }
        },

        validationSchema: (props: any) => {
          return Yup.object().shape({
            oldPassword: Yup.string().required(
              props.t('security:validation_old_password'),
            ),
            newPassword: Yup.string().required(
              props.t('security:validation_new_password'),
            ),
            confirmPassword: Yup.string()
              .oneOf(
                [Yup.ref('newPassword'), null],
                props.t('security:validation_password_must_match'),
              )
              .required(props.t('security:validation_confirm_password')),
          })
        },

        handleSubmit: (values, { props, setSubmitting }) => {
          props.mutate({
            variables: values,
            update: (
              cache: any,
              { data }: { data: { error: IGenericError } },
            ) => {
              if (data.error) {
                setSubmitting(false)
                props.setAlert({ type: 'error', text: data.error.message, size: 'small' })
              } else {
                props.onCloseModal()
                props.setAlert({
                  type: 'success',
                  text: props.t('security:validation_confirm'),
                  size: 'small'
                })
              }
            },
          }).catch(() => {
            setSubmitting(false)
          })
        },
      })(Content),
  ),
)

export default FormPassword
