import { UsermailsFiltersProps } from '@/interfaces/Filters'
import IUserMail from '@/interfaces/UserMail'
import { TailwindConfig } from '@sedomicilier/material-sd'
import { useWindowDimensions } from 'src/components/shared/useWindowDimensions'
import { Checkbox, DatePicker } from 'antd'
import dayjs from 'dayjs'
import moment from 'moment'
import { Dispatch, SetStateAction } from 'react'
import { SelectedMailsProps } from '@/CustomerArea/userMails/UserMails/ListingMails'
import ReadUnreadHandler from '@/CustomerArea/userMails/UserMails/ListingMails/Filters/readUnreadHandler'
import PageLengthSetter from '@/CustomerArea/userMails/UserMails/ListingMails/Filters/pageLengthSetter'
import { useTranslation } from 'react-i18next'

interface DateFilterAndMailsSelectionProps {
  onFilterChange: (prev) => void
  userMails: IUserMail[] | undefined
  selectedMails: SelectedMailsProps[]
  setSelectedMails: Dispatch<SetStateAction<SelectedMailsProps[]>>
  setCurrentPage: Dispatch<SetStateAction<number>>
  setPageLength: Dispatch<SetStateAction<number>>
}

const DateFilterAndMailsSelection: React.FC<DateFilterAndMailsSelectionProps> = ({
  onFilterChange,
  userMails,
  selectedMails,
  setSelectedMails,
  setPageLength,
  setCurrentPage
}) => {
  const { t } = useTranslation()
  const { RangePicker } = DatePicker
  const { width } = useWindowDimensions()
  const mobile = width <= parseInt(TailwindConfig.breakpointsCustomerArea.mobile.max, 10)

  const allMailIds = userMails?.map((mail) => ({ id: mail.id, viewed: mail.viewed })) || []

  const datePickerSelect = (dates) => {
    if (!dates) {
      return onFilterChange((prev: UsermailsFiltersProps) => ({
        ...prev,
        dateOfReceiptFrom: undefined,
        dateOfReceiptTo: undefined
      }))
    }
    if (dates && dates[1] !== null) {
      return onFilterChange((prev: UsermailsFiltersProps) => ({
        ...prev,
        dateOfReceiptFrom: moment(dates[0].$d.toString()).toDate(),
        dateOfReceiptTo: moment(dates[1].$d.toString()).toDate()
      }))
    }
  }

  const onChecked = (checked: boolean) => {
    if (checked) {
      setSelectedMails(allMailIds)
    } else {
      setSelectedMails([])
    }
  }

  const indeterminate = selectedMails.length !== 0 && userMails?.length !== selectedMails.length

  return (
    <div className='mobile:flex mobile:flex-col mobile:gap-1'>
      {/* design requires element order change */}
      {mobile && (
        <RangePicker
          className='mobile:w-full'
          placeholder={[t('userMails:filters.rangePicker.start'), t('userMails:filters.rangePicker.end')]}
          format={'DD/MM/YYYY'}
          onChange={(dates) => datePickerSelect(dates)}
          placement='topRight'
          maxDate={dayjs()}
        />
      )}
      <div className='pl-[17px] flex items-center justify-between gap-1'>
        <div className='w-fit flex items-center gap-3'>
          <Checkbox indeterminate={indeterminate} onChange={(e) => onChecked(e.target.checked)} />
          <ReadUnreadHandler selectedMails={selectedMails} setSelectedMails={setSelectedMails} />
        </div>
        {!mobile && (
          <RangePicker
            className='w-[400px]'
            placeholder={[t('userMails:filters.rangePicker.start'), t('userMails:filters.rangePicker.end')]}
            format={'DD/MM/YYYY'}
            onChange={(dates) => datePickerSelect(dates)}
            placement='topRight'
            maxDate={dayjs()}
          />
        )}

        <PageLengthSetter
          setPageLength={setPageLength}
          setSelectedMails={setSelectedMails}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  )
}

export default DateFilterAndMailsSelection
