import React, { useContext } from 'react'
import styled from 'styled-components'
import UserMailAction from './UserMailAction'
import UserMailHistorical from './UserMailHistorical'
import UserMailPreview from './UserMailPreview'
import UserMailTags from './UserMailTags'
import BackLinkIcon from 'src/images/chevron-left-blue.svg'
import { useWindowDimensions } from 'src/components/shared/useWindowDimensions'
import { UserMailContext } from '../../../../contexts/UserMailContext'
import { TailwindConfig } from '@sedomicilier/material-sd'

const UserMailDetails = () => {
  const { setSelectedMail } = useContext(UserMailContext)
  const { width } = useWindowDimensions()
  const mobile = width <= parseInt(TailwindConfig.breakpointsCustomerArea.mobile.max, 10)

  return (
    <RightSideWrapper>
      {mobile && <BackLink src={BackLinkIcon} alt='icon chevron gauche' onClick={() => setSelectedMail(undefined)} />}
      <UserMailPreview />
      <UserMailAction />
      <UserMailTags />
      <UserMailHistorical />
    </RightSideWrapper>
  )
}

export const RightSideWrapper = styled.div`
  height: calc(100vh - 140px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`
export const BackLink = styled.img`
  margin-bottom: 40px;
  &:hover {
    cursor: pointer;
  }
`

export default UserMailDetails
