import React, { Dispatch, SetStateAction, useContext } from 'react'
import IUserMail from 'src/interfaces/UserMail'
import moment from 'moment'
import { useWindowDimensions } from 'src/components/shared/useWindowDimensions'
import { useMutation } from '@apollo/client'
import { USER_MAIL_VIEW_MUTATION } from 'src/graphql/UserMail'
import { NotificationContext } from 'src/contexts/notificationContext'
import { Checkbox } from 'antd'
import { SelectedMailsProps } from '@/CustomerArea/userMails/UserMails/ListingMails'
import { UserMailContext } from '@/contexts/UserMailContext'
import MailTypeIcon from '@/CustomerArea/userMails/UserMails/shared/MailTypeIcon'
import MailInformationStatus from '@/CustomerArea/userMails/UserMails/shared/MailInformationStatus'
import MailCardDownload from '@/CustomerArea/userMails/UserMails/shared/MailCardDownload'
import { TailwindConfig } from '@sedomicilier/material-sd'

interface IMailCardProps {
  mail: IUserMail
  selectedMails: SelectedMailsProps[]
  setSelectedMails: Dispatch<SetStateAction<SelectedMailsProps[]>>
}

const MailCard: React.FC<IMailCardProps> = ({ mail, setSelectedMails, selectedMails }) => {
  const { refetch: refetchNotifications } = useContext(NotificationContext)
  const { setSelectedMail, selectedMail } = useContext(UserMailContext)

  const { width } = useWindowDimensions()
  const mobile = width <= parseInt(TailwindConfig.breakpointsCustomerArea.mobile.max, 10)

  const [mailViewed] = useMutation(USER_MAIL_VIEW_MUTATION, {
    variables: {
      userMailIds: [mail.id]
    },
    onCompleted: () => {
      // here we update the array of checked mails with new state for viewed, to keep visuals dynamical
      const item = selectedMails.find((mail) => mail.id === selectedMail?.id)
      if (item) {
        setSelectedMails((prevMails) =>
          prevMails.map((mail) => (mail.id === selectedMail?.id ? { ...mail, viewed: true } : mail))
        )
      }
      return
    }
  })
  const onMailClick = () => {
    setSelectedMail(mail)
    if (!mail.viewed) {
      mailViewed()
      refetchNotifications()
    }
  }

  const onChecked = (checked: boolean) => {
    if (checked) {
      setSelectedMails((prev) => [...prev, { id: mail.id, viewed: mail.viewed }])
    } else {
      setSelectedMails((prev) => {
        const newArray = prev.filter((item) => item.id !== mail.id)
        return newArray
      })
    }
  }

  const viewedBg = mail.viewed ? 'bg-neutral-0' : 'bg-primary-50'
  const mailChecked = selectedMails.some((item) => item.id === mail.id) ? '!border-interaction' : ''
  const mailSelected = selectedMail?.id === mail.id ? 'border-2 p-[15px] border-interaction' : 'border-neutral-100'

  const cardMailStyle = `flex items-center justify-between p-2 rounded-small mb-1 border cursor-pointer hover:border-interaction [&_p]:mb-0 ${viewedBg} ${mailSelected} ${mailChecked}`

  return (
    <div onClick={onMailClick} className={cardMailStyle}>
      <div className='flex items-center gap-2'>
        <div onClick={(e) => e.stopPropagation()}>
          <Checkbox
            checked={selectedMails.some((item) => item.id === mail.id)}
            onChange={(e) => onChecked(e.target.checked)}
          />
        </div>
        <MailTypeIcon
          mailType={mail.mailType}
          viewed={mail.viewed}
          noMoreActionAvailable={mail?.allowedOptions.length === 0}
        />
        <MailInformationStatus mail={mail} />
      </div>
      {!mobile && (
        <div className='flex items-center gap-3'>
          <MailCardDownload mail={mail} />
          <div className='text-7 text-[#7b7e80]'>{moment(mail.dateOfReceipt).format('DD/MM/YY')}</div>
        </div>
      )}
    </div>
  )
}

export default MailCard
