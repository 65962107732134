import { useLocation, useMatch } from 'react-router-dom'
import ProtectedLink from 'src/components/ProtectedLink'
import Notification from '../Notifications'
import { ReactNode } from 'react'

interface SideMenuSectionProps {
  route: string
  authorized: boolean
  dataSpecId: string
  icon: ReactNode
  display: boolean
  sectionTitle?: string
  notification?: number
  logo?: boolean
  affiliated?: boolean
}

const SideMenuSection = ({
  route,
  authorized,
  dataSpecId,
  icon,
  sectionTitle,
  display,
  notification,
  logo
}: SideMenuSectionProps) => {
  const location = useLocation()
  const basePath = location.pathname.split('/')[1]
  const match = useMatch(`${basePath}/*`)

  const active = route === match?.pathnameBase ? 'bg-neutral-200' : 'hover:bg-neutral-150'

  if (display) {
    return (
      <div className='body-2 h-fit [&_.disabled-nav-link]:opacity-50 [&_.disabled-nav-link]:cursor-default'>
        <ProtectedLink
          to={route}
          className={`flex items-center gap-2 rounded-small ${logo ? 'm-0 p-[0px_16px_16px]' : `${active} py-1 px-2 desktop:my-0.5 desktop:mx-0`}`}
          authorized={authorized}
          data-spec-id={dataSpecId}
        >
          {icon}
          <span className='flex-1 text-5 text-neutral-700'>{sectionTitle}</span>
          <Notification number={notification} />
        </ProtectedLink>
      </div>
    )
  } else {
    return null
  }
}

export default SideMenuSection
