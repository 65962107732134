import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { Drawer } from 'antd'
import session from 'src/session'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { ContactContext } from 'src/contexts/contactContext'

const AdministratorAccess = () => {
  const { domiciliation } = useContext(DomiciliationContext)
  const { contact } = useContext(ContactContext)

  const [open, setOpen] = useState(false)
  const decodedToken = session.getDecodedToken()
  const domiciliationId = domiciliation?.id
  const countryCode = contact.countryConfig.countryCode
  const baseUrl = process.env['REACT_APP_HOME_LINK_' + countryCode]
  const addressId = domiciliation?.address?.id

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  if (!decodedToken?.connected_as) {
    return <div />
  }

  return (
    <>
      <button className='section-button primary-button hidden-mobile' onClick={() => showDrawer()}>
        Accès administrateur
      </button>
      <Drawer title='Accès administrateur' placement='right' onClose={onClose} open={open} closable={true}>
        <Wrapper>
          <span translate='no' className='notranslate'>
            {contact.society.name || 'Mon compte'} #{contact.id}
          </span>
          <a
            href={`${baseUrl}/admin2/admin/moderations/${domiciliationId}/moderate`}
            className='section-button primary-button'
            target='_blank'
            rel='noreferrer'
          >
            Moderation
          </a>
          <a
            href={`${baseUrl}/admin2/admin/customers/${domiciliationId}`}
            className='section-button primary-button'
            target='_blank'
            rel='noreferrer'
          >
            Client
          </a>
          {addressId && (
            <a
              href={
                `${baseUrl}/admin2/manager/addresses/${addressId}/domiciliations/` + `${domiciliationId}/user_mails`
              }
              className='section-button primary-button'
              target='_blank'
              rel='noreferrer'
            >
              Courrier
            </a>
          )}
          <a
            href={`/?token=${session.getSession()}`}
            className='section-button primary-button'
            target='_blank'
            rel='noreferrer'
          >
            Nouvel onglet
          </a>
        </Wrapper>
      </Drawer>
    </>
  )
}
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  a,
  span {
    margin: 10px 25px;
    text-align: center;
  }
`

export default AdministratorAccess
