import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCreditCard,
  faUniversity,
} from '@sedomicilier/pro-regular-svg-icons'
import { Tag } from 'antd'
import IPaymentSource from 'src/interfaces/PaymentSource'
import { MutationFunction } from '@apollo/client'
import confirmAlert from '@/components/shared/ConfirmAlert'
import { Can } from 'src/contexts/abilityContext'
import RemoveLink from './components/RemoveLink'
import MarkAsDefaultLink from './components/MarkAsDefaultLink'

const CurrentPaymentSourceDisplay = (props: IPaymentSource) => {
  const { t } = useTranslation()
  if (props.currentSource) {
    return <span className="info-sub">{t('payments:defaultPayment')}</span>
  } else {
    return null
  }
}

const Payment = (props: IPaymentSource) => {
  const { t } = useTranslation()

  const remove = (paymentSourceDestroy: MutationFunction) => {
    confirmAlert({
      title: t('common:areyousure'),
      buttons: [
        {
          label: t('common:cancel'),
          onClick: () => console.log('Cancel'),
        },
        {
          primary: true,
          label: t('common:confirm'),
          onClick: () => {
            paymentSourceDestroy({
              variables: { id: props.id },
            })
          },
        },
      ],
    })
  }

  const markAsDefault = (paymentSourceMarkAsDefault: MutationFunction) => {
    confirmAlert({
      title: t('common:areyousure'),
      buttons: [
        {
          label: t('common:cancel'),
        },
        {
          primary: true,
          label: t('common:confirm'),
          onClick: () => {
            paymentSourceMarkAsDefault({
              variables: { id: props.id },
            })
          },
        },
      ],
    })
  }

  return (
    <div
      className={`full-border payment-element ${
        props.currentSource ? 'default' : ''
      }`}
    >
      <div className="method-title">
        <p>{t(`payments:${props.name}`)}</p>
      </div>

      <div className="method-sumup">
        <FontAwesomeIcon
          icon={props.sourceType === 'card' ? faCreditCard : faUniversity}
          className="carte-paiement"
        />
        <p>
          <span className="last4">{`• • • • ${props.last4Digits}`}</span>
          {props.expirationDate && (
            <span className="info-sub">
              {t('payments:expire_at')}{' '}
              {t('common:date', {
                date: new Date(props.expirationDate),
              })}
            </span>
          )}
        </p>
        <span className="card-status">
          {props.state === 'expired' && <Tag color="red">Carte expirée</Tag>}
          {props.state === 'failed' && <Tag color="red">Paiement échoué</Tag>}
        </span>
      </div>

      <div className="action-links">
        <p>
          <CurrentPaymentSourceDisplay {...props} />
          <Can I="customer_manage" a="PaymentSource">
            <RemoveLink {...props} remove={remove} />
            <MarkAsDefaultLink {...props} markAsDefault={markAsDefault} />
          </Can>
        </p>
      </div>
    </div>
  )
}

export default Payment
