import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { isDemo } from './demo/Utils'
import Zendesk from 'react-zendesk'
import DemoHeader from './demo/DemoHeader'
import DomiciliationCheck from './DomiciliationCheck'
import { titleFromLocation } from '@/components/shared/utilities/titleFromLocation'
import { DomiciliationContext } from 'src/contexts/DomiciliationContext'
import { ContactContext } from 'src/contexts/contactContext'
import { CurrentUserContext } from 'src/contexts/currentUserContext'
import Topbar from 'src/components/layouts/Topbar'
import SideMenu from 'src/components/layouts/SideMenu'
import StripeWrapper from 'src/components/StripeWrapper'
import AlertPopup from 'src/components/UIKit/components/AlertPopup'
import { zendeskKey, zendeskSettings } from 'src/components/ZendeskConfig'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useWindowDimensions } from 'src/components/shared/useWindowDimensions'
import { TailwindConfig } from '@sedomicilier/material-sd'
import MobileMenu from '@/components/layouts/MobileMenu'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import i18n from '@/i18n'


interface IProps {
  children: React.ReactNode
}

const Layout: React.FC<IProps> = ({ children }) => {
  const { t, i18n } = useTranslation()
  const location = useLocation()

  const { width } = useWindowDimensions()

  const isLessThanTablet = width <= parseInt(TailwindConfig.breakpointsCustomerArea.desktop.min, 10)

  const { domiciliation } = useContext(DomiciliationContext)
  const { contact } = useContext(ContactContext)
  const currentUser = useContext(CurrentUserContext)
  const affiliated = domiciliation?.affiliated

  const [title, setTitle] = useState(titleFromLocation(location.pathname, t))
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false)

  const [stripeConnectedAccount, setStripeConnectedAccount] = useState(contact?.stripeConnectedAccount)

  useEffect(() => {
    const updateTitle = () => setTitle(titleFromLocation(location.pathname, t))
    updateTitle()
    i18n.on('languageChanged', updateTitle)
    return () => {
      i18n.off('languageChanged', updateTitle)
    }
  }, [location.pathname, t, i18n])

  useEffect(() => {
    if (contact?.stripeConnectedAccount) {
      setStripeConnectedAccount(contact.stripeConnectedAccount)
    }
  }, [contact?.stripeConnectedAccount])

  return (
    <DomiciliationCheck>
      <StripeWrapper stripeConnectedAccount={stripeConnectedAccount}>
        {isDemo() && <DemoHeader />}
        {!isDemo() && (
          <Zendesk
            zendeskKey={zendeskKey({ domiciliation })}
            {...zendeskSettings({ domiciliation, contact, currentUser })}
          />
        )}
        <AppWrapper>
          <SideMenu />
          <ContentWrapper>
            <header>
              {!affiliated && (
                <React.Fragment>
                  <AlertPopup />
                  <Topbar showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu} />
                </React.Fragment>
              )}
            </header>
            {isLessThanTablet && showMobileMenu ? (
              <MobileMenu setShowMobileMenu={setShowMobileMenu} />
            ) : (
              <MainContentWrapper role='main' className={affiliated ? 'affiliatedLayout' : ''}>
                <MainTitle>{title}</MainTitle>
                {children}
              </MainContentWrapper>
            )}
          </ContentWrapper>
        </AppWrapper>
      </StripeWrapper>
    </DomiciliationCheck>
  )
}

export const AppWrapper = styled.div`
  display: flex;
  height: 100%;
`
export const ContentWrapper = styled.div`
  width: calc(100% - 232px);
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 32px;
  padding-bottom: 40px;
  overflow: auto;
`
export const MainContentWrapper = styled.main`
  padding: 0;
`
export const MainTitle = styled.h1`
  font-size: 24px;
  font-family: 'Inter';
  font-weight: 700;
  padding: 0 45px;
  margin-bottom: 32px;
`
export default Layout
