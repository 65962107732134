import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import Cart from '../components/Cart'
import { Page } from 'src/components/UIKit'
import { CART_REMOVE_PURCHASES } from 'src/graphql/Cart'
import { Box, ContentRow } from '../styles'
import { Constants } from 'src/components/UIKit'
import { CurrentCartContext } from 'src/contexts/currentCartContext'
import ProductIcon from 'src/components/shared/ProductIcon'
import Item from './Item'
import Routing from '@/CustomerArea/Routing'

const Summary = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const currentCart = useContext(CurrentCartContext)
  const [cartRemovePurchases] = useMutation(CART_REMOVE_PURCHASES)

  const gotoBilling = () => {
    setIsSubmitting(true)
    navigate(`${Routing.CART}/payment`)
  }

  const removePurchase = (purchaseId) => {
    cartRemovePurchases({ variables: { purchaseIds: [purchaseId] } })
  }

  return (
    <Wrapper>
      {/* left side */}
      <ContentRow>
        <Box>
          <h3>{t('common:cart')}</h3>
          <div data-test='cart-summary'>
            {currentCart?.purchases?.length === 0 && <span>{t('common:emptyCart')}</span>}
            {currentCart?.purchases?.length > 0 &&
              currentCart?.purchases?.map((purchase, index) => {
                return (
                  <Line key={`purchase_${index}`}>
                    <ProductIcon identificationToken={purchase.identificationToken} />
                    <Item removePurchase={removePurchase} purchase={purchase} />
                  </Line>
                )
              })}
          </div>
        </Box>
        {/* end of left side */}

        {/* right side */}
        {currentCart?.purchases?.length > 0 && (
          <Cart
            disabledButton={isSubmitting}
            paymentButtonText={t('payments:go_to_payment')}
            displayButtonSpinner={isSubmitting}
            confirmOnClick={gotoBilling}
          />
        )}
      </ContentRow>
    </Wrapper>
  )
}

const Wrapper = styled(Page)`
  padding: 70px 40px;
`

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 50px;
  width: -webkit-fill-available;
  width: -moz-available;

  margin: 0 0 25px 0;

  @media (max-width: ${Constants.breakpoints['mobile-breakpoint-i']}) {
    align-items: flex-start;
    height: fit-content;
    margin: 0;
    padding: 25px 0 25px 0;
    border-bottom: 1px solid #e3e6e8;
  }

  @media (min-width: ${Constants.breakpoints['mobile-breakpoint-o']}) {
    &:last-child {
      margin: 0;
    }
  }

  &:first-child {
    margin-top: 30px;
  }
`

export default Summary
