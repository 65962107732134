import { useTranslation } from 'react-i18next'
import { BasicButton, CustomIcon, LegalflowSection, NoticeInformation } from '@sedomicilier/material-sd'
import DocumentsStorageOptions from '@/app/LegalFlow/Accounting/documentsStorageOptions'
import { useContext, useState } from 'react'
import AccountantInfos from '@/app/LegalFlow/Accounting/accountantInfos'
import { Form } from 'antd'
import AddressFormItems from '@/components/UIKit/components/AddressFormItems'
import { useMutation } from '@apollo/client'
import { UPSERT_DOCUMENT_HOST } from '@/graphql/mutations/upsertDocumentsHost'
import { useDispatch } from 'react-redux'
import { nextPage } from '@/reducers/workflow'
import { DomiciliationContext } from '@/contexts/DomiciliationContext'
import { ContactContext } from 'src/contexts/contactContext'

const Accounting: React.FC = () => {
  const { contact } = useContext(ContactContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const { domiciliation } = useContext(DomiciliationContext)

  const documentHost = domiciliation.documentHost

  const [loading, setLoading] = useState<boolean>(false)
  const [storageLocation, setStorageLocation] = useState<string | undefined>(documentHost?.location)

  const [upsertDocumentsHost] = useMutation(UPSERT_DOCUMENT_HOST)

  const onFinish = (values) => {
    if (!loading) {
      setLoading(true)
      const address = values?.address

      upsertDocumentsHost({
        variables: {
          location: storageLocation,
          email: values?.accountantEmail,
          accountantName: values?.accountantName,
          streetAddress: address?.streetAddress,
          postalCode: address?.postalCode,
          city: address?.city,
          country: address?.countryCode,
          noAccountant: storageLocation === 'self'
        }
      }).then(() => dispatch(nextPage(contact.hideDomideal ? 'hidedomideal' : '')))
    }
  }

  const initialValues = {
    accountantEmail: documentHost?.email,
    accountantName: documentHost?.accountantName,
    address: documentHost?.fullAddress
  }

  return (
    <LegalflowSection title={t('accounting:title')}>
      <p className='body-1 font-semibold mb-2'>{t('accounting:subtitle')}</p>

      <DocumentsStorageOptions storageLocation={storageLocation} setStorageLocation={setStorageLocation} />

      <NoticeInformation
        bgColor='grey'
        icon={<CustomIcon icon={<span className={'ico-star-full text-m text-interaction'} />} />}
        className='body-2 mb-3'
      >
        {t('accounting:noticeInformation')}
      </NoticeInformation>
      <Form
        name='accountingForm'
        form={form}
        layout='vertical'
        requiredMark={false}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        {storageLocation === 'accountant' && <AccountantInfos />}

        {(storageLocation === 'accountant' || storageLocation === 'other') && (
          <AddressFormItems
            form={form}
            initialAddress={documentHost?.fullAddress}
            addressLabel={t('common:address')}
            addressValidation={t('common:validation.commonAddress')}
          />
        )}
        <BasicButton
          testid='accounting-next-button'
          bgColor='blue'
          loading={loading}
          disabled={!storageLocation}
          label={t('common:continue')}
          className='justify-center body-1 font-semibold mt-4 w-full'
        />
      </Form>
    </LegalflowSection>
  )
}

export default Accounting
