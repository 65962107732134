import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Menu, Dropdown, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { USER_REMOVE_MUTATION } from 'src/graphql/User'
import Avatar from '@/components/shared/Avatar'
import DropdownButton from '@/images/dropdown-button.svg'
import UpdateRoleModal from './UpdateRoleModal'
import { openValidationPopup, openErrorPopup } from '@/components/shared/ConfirmationPopups'
import { CurrentUserContext } from 'src/contexts/currentUserContext'
import { AbilityContext } from 'src/contexts/abilityContext'
import IUser from '@/interfaces/User'

interface IProps {
  user: IUser
  refetchList: any
}

const User = (props: IProps) => {
  const { t } = useTranslation()
  const { user } = props
  const [updateRoleModal, setUpdateRoleModal] = useState(false)
  const currentUser = useContext(CurrentUserContext)
  const ability: any = useContext(AbilityContext)

  const [userRemove] = useMutation(USER_REMOVE_MUTATION)

  const { confirm } = Modal

  const showDeleteConfirm = () => {
    confirm({
      title: t('team:remove_user.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('team:remove_user.description'),
      okText: t('team:remove_user.button_confirm'),
      okType: 'danger',
      cancelText: t('team:remove_user.button_cancel'),
      onOk() {
        userRemove({ variables: { userId: user.id } }).then(() => {
          openValidationPopup(
            t('team:remove_user.popup_success_title'),
            t('team:remove_user.popup_success_description', { email: user.email }),
          )
          props.refetchList()
        }).catch((e) => openErrorPopup(t('team:remove_user.popup_error_title'), e.message))
      },
    })
  }

  const menu = (
    <Menu>
      <Menu.Item key={`menu_item.delete.${user.id}`}>
        {
          ability.can('customer_manage', 'User') ?
            <a href={void(0)} onClick={() => showDeleteConfirm()}>{t('team:remove_user.link')}</a> :
            <Disabled>{t('team:remove_user.link')}</Disabled>
        }
      </Menu.Item>
      <Menu.Item key={`menu_item.update.${user.id}`}>
        <a href={void(0)} onClick={() => setUpdateRoleModal(true)}>{t('team:update_user_role.link')}</a>
      </Menu.Item>
    </Menu>
  )

  const avatarName = (user.firstname && user.lastname) ? `${user.firstname} ${user.lastname}` : user.email

  return (
    <>
      <UpdateRoleModal
        openModal={updateRoleModal}
        setOpenModal={setUpdateRoleModal}
        refetch={props.refetchList}
        userId={user.id}
        roleId={user.role.id}
        email={user.email}
      />
      <div className="team-row" key={user.id}>
        <div className="team-column team-name items-center flex">
          <AvatarWrapper className="flex-initial">
            <Avatar size={30} name={avatarName} emailMd5={user.emailMd5!} />
          </AvatarWrapper>
          <IdentityWrapper className="flex-1">
            <Name>
              <span translate="no" className="notranslate">
                {
                  user.firstname && user.lastname &&
                  `${user.firstname} ${user.lastname} `
                }
                {`${currentUser.id === user.id ? `(${t('common:you')})` : ''}`}
              </span>
            </Name>
            <Email>{user.email}</Email>
          </IdentityWrapper>
        </div>
        <div className="team-column team-role">{t(`team:roles.${user.role.name}.name`)}</div>
        <div className="team-column team-permission">{t(`team:roles.${user.role.name}.description`)}</div>
        <div className="team-column team-action">
          {
            user.role.name !== 'owner' &&
            <Dropdown overlay={menu} trigger={['click']} overlayClassName="team-dropdown">
              <img src={DropdownButton} className="team-dropdown-button" />
            </Dropdown>
          }
        </div>
      </div>
    </>
  )
}

const AvatarWrapper = styled.div`
  display: inline-block;
  vertical-align: top;
`

const IdentityWrapper = styled.div`
  display: inline-block;
  vertical-align: sub;
  margin-left: 20px;
`

const Name = styled.span`
  display: block;
  font-weight: 700;
`

const Email = styled.span`
  display: block;
  color: #7B7E80
`

const Disabled = styled.span`
  opacity: 50%;
  cursor: default;
`

export default User
