import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_TAG_COMMANDER_VARS } from 'src/graphql/TagCommander'
import { CommonLoader } from 'src/components/shared/Loader'
/* eslint-disable camelcase */
import TC_Wrapper from 'react-tag-commander'

interface ITagCommanderContext {
  dataTagCommander: any
  loadingTagCommander: any
  errorTagCommander: any
  tcReloadOnly: any
}

export const TagCommanderContext = React.createContext<ITagCommanderContext | null>(null)
export const TagCommanderContextProvider = TagCommanderContext.Provider
export const TagCommanderContextConsumer = TagCommanderContext.Consumer

const device = () => {
  if (window.innerWidth > 1250) {
    return 'd' // desktop
  } else if (window.innerWidth > 900) {
    return 't' // tablet
  } else {
    return 'm' // mobile
  }
}
const commonVars = {
  env_category: 'espace-client',
  env_work: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'prod' : 'preprod',
  env_page: window.location.pathname,
  env_url: window.location.href,
  env_device: device(),
}

const tagIds: { [key: string]: string } = {
  FR: '5477',
  BE: '5881',
}

const wrapper = TC_Wrapper.getInstance()

const tcEndpoint = () => {
  return (process.env.REACT_APP_ENVIRONMENT === 'production' ? '/' : '/uat/')
}

interface IProps {
  children: React.ReactNode
}

const TagCommanderProvider = (props: IProps) => {
  const [tcReadied, setTCReady] = React.useState(false)
  const { data, loading, error } = useQuery(GET_TAG_COMMANDER_VARS)

  useEffect( () => {
    if (data) {
      const resultData = JSON.parse(data.tcVars)

      if (process.env.REACT_APP_ENVIRONMENT !== 'production' &&
          process.env.REACT_APP_TAGCOMMANDER_DISABLE_LOGS !== 'true') {
        wrapper.setDebug(true)
      } else {
        wrapper.setDebug(false)
      }

      wrapper.setTcVars(resultData)

      setTimeout(() => {
        setTCReady(true)
      }, 4000)

      Promise.all([
        wrapper.addContainer(
          '20',
          `//cdn.tagcommander.com/${tagIds[resultData.env_country]}${tcEndpoint()}tc_SeDomicilier_20.js`,
          'head',
        ),
        wrapper.addContainer(
          '22',
          `//cdn.tagcommander.com/${tagIds[resultData.env_country]}${tcEndpoint()}tc_SeDomicilier_22.js`,
          'head',
        ),
        wrapper.addContainer(
          '24',
          `//cdn.tagcommander.com/${tagIds[resultData.env_country]}${tcEndpoint()}tc_SeDomicilier_24.js`,
          'head',
        ),
      ]).then(() => {
        setTCReady(true)
      })
    }
  }, [data])

  if (tcReadied) {
    const resultData = JSON.parse(data.tcVars)

    return (
      <TagCommanderContextProvider value={{
        dataTagCommander: data,
        loadingTagCommander: loading,
        errorTagCommander: error,
        tcReloadOnly: { ...resultData, ...commonVars },
      }}>
        {props.children}
      </TagCommanderContextProvider>
    )
  } else {
    return (
      <CommonLoader size={48} />
    )
  }
}

export default TagCommanderProvider
