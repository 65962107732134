import { useContext } from 'react'
import { DomiciliationContext } from '@/contexts/DomiciliationContext'
import ActionsMails from '@/CustomerArea/userMails/UserMails/ActionsMails'
import ListingMails from '@/CustomerArea/userMails/UserMails/ListingMails'
import Pushes from '@/CustomerArea/userMails/UserMails/pushes'
import UserAddressInformations from '@/CustomerArea/userMails/UserMails/shared/UserAddressInformations'

const MailsBox: React.FC = () => {
  const { domiciliation } = useContext(DomiciliationContext)
  const affiliated = domiciliation.affiliated
  const padding = affiliated ? 'p-0 pt-[45px]' : 'pt-[45px] pb-0 px-5 mobile:px-3'

  return (
    <div
      // eslint-disable-next-line max-len
      className={`w-[60%] -mt-[45px] border-r border-r-[#e1e4e680] mobile:border-none mobile:w-full mobile:my-0 mobile:mx-auto ${padding}`}
    >
      <UserAddressInformations />
      <Pushes />
      <ListingMails />
      <ActionsMails />
    </div>
  )
}

export default MailsBox
