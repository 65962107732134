import React from 'react'

interface IMailTypeIcon {
  mailType: string
  viewed: boolean
  noMoreActionAvailable: boolean
}

const MailTypeIcon: React.FC<IMailTypeIcon> = ({ mailType, viewed, noMoreActionAvailable }) => {
  const type = mailType === 'Colis' ? 'parcel' : 'letter'
  const viewedState = viewed ? 'open' : 'close'
  const bgColor = noMoreActionAvailable ? 'bg-neutral-50 text-neutral-700' : 'bg-yellow-50 text-yellow-700'

  return <span className={`ico-${type}-${viewedState} text-2 p-[10px] rounded-round ${bgColor}`} />
}

export default MailTypeIcon
