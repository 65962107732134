import React, { useContext } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { UserMailContext } from '../../../../contexts/UserMailContext'
import { useTranslation } from 'react-i18next'

const UserMailHistorical = () => {
  const { selectedMail }: any = useContext(UserMailContext)
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <SideHistoricalTitle>{t('userMails:global.historique')}</SideHistoricalTitle>
      <HistoricalContent>
        {selectedMail?.lifecycleSteps?.map((item, index) => {
          return (
            <StatusWrapper key={index}>
              <StepDot currentStep={index === 0 ? true : false} />
              <StatusContent>
                <ProcessingType>{t('userMails:status.' + item.title)}</ProcessingType>
                {item.processingDate && (
                  <ProcessingDetails>Le {moment(item.processingDate).locale('fr').format('D MMMM')}</ProcessingDetails>
                )}
                {item.until && (
                  <ProcessingDetails>
                    {t('userMails:global.until')} {moment(item.until).locale('fr').format('D MMMM')}
                  </ProcessingDetails>
                )}
                {item.title === 'forwarded' && selectedMail.trackingNumber && (
                  <ProcessingDetails>
                    {t('userMails:global.trackingNumber')}&nbsp;
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href={`https://www.laposte.fr/outils/suivre-vos-envois?code=${selectedMail.trackingNumber}`}
                    >
                      {selectedMail.trackingNumber}
                    </a>
                  </ProcessingDetails>
                )}
              </StatusContent>
            </StatusWrapper>
          )
        })}
      </HistoricalContent>
    </React.Fragment>
  )
}

export const SideHistoricalTitle = styled.h4`
  color: #171a1c;
  font-size: 20px;
  font-weight: 600;
`
export const HistoricalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 22px;
  padding: 16px;
  border: 1px solid #e3e6e8;
  border-radius: 8px;
`

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StepDot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background: ${(props) => (props.currentStep ? '#1dbe6d' : '#D2DBE1')};
  margin-right: 16px;
`

export const StatusContent = styled.div`
  color: #171a1c;
`

export const ProcessingType = styled.div`
  font-size: 14px;
  font-weight: 600;
`

export const ProcessingDetails = styled.div`
  font-size: 12px;
  font-weight: 400;
`

export default UserMailHistorical
